@charset "UTF-8";

:root {
    --color-theme-black: #101010;
    --color-theme-white: #ffffff;
    --color-theme-dark-white: #f0f0f0;

    --color-theme-dark-grey: #797979;
    --color-theme-light-grey: #dfe0e2;
    --color-theme-grey: #ccc;
    --color-theme-green: #4dca81;
    --color-theme-red: #e91a21;

    --color-theme-primary: #2086c9;
    --color-theme-secondary: #fdbd22;
    --color-theme-background-primary: #f7f9fd;
    --color-theme-background-secondary: #193366;

    --color-theme-font-primary: #101010;
    --color-theme-font-secondary: #303030;

    --color-theme-alert-danger: #e91a21;    

    --cc-btn-primary-bg: var(--color-theme-primary) !important;
    --cc-btn-primary-hover-bg: #186293 !important;
    --cc-btn-secondary-hover-bg: --color-theme-background-primary !important;
    --cc-btn-border-radius: 4px !important;
    --cc-toggle-bg-off: var(--color-theme-alert-danger) !important;
    --cc-toggle-bg-on: var(--color-theme-green) !important;
    --cc-toggle-bg-readonly: #186293 !important;
    --cc-cookie-category-block-bg: --color-theme-background-primary !important;

    --icons-theme: url('/assets/images/waves.svg');
    --footer-theme: url('/assets/images/baltic-sea.webp');

    --filter-primary-theme: invert(48%) sepia(98%) saturate(498%) hue-rotate(168deg) brightness(79%) contrast(100%);

    --swiper-navigation-size: 26px;
}

